// Font size
$base-font-size: 1.4rem;

// Line height
$base-line-height: 2.28;
$heading-line-height: 1.4;

// Color
$blue: #0d97e2;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

$base-font-color: #333333;
$base-action-color: #333333;

// Background Colors
$base-background-color: #fff;

// Font-family
$font-gothic: YakuHanJP, "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
$font-mincho: serif, 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ Ｐ明朝','MS PMincho',sans-serif;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Focus
$focus-outline-color: transparentize($base-action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;


// Animations
$base-duration: 150ms;
$base-timing: ease;


$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba($base-action-color, 0.7);
