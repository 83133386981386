@use "./setting/setting" as m;
@use "variable" as var;

.recruit{
  position: relative;
  &_last{
    width: 168.5px;
    height: 240px;
    position: absolute;
    left: calc(16.66vw - 142px);
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 11;
    @include m.mq('middle') {
      display: none;
    }
  }

  &_slider{
    display: flex;
    justify-content: space-between;
    margin-bottom: 190px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    .slick-vertical .slick-slide{
      border: none;
      line-height: 1;
    }
    &_list{
      width: 33.33%;
      div{
        line-height: 0;
      }
      &_item{
        margin: 0 !important;
        img{
        }
      }
      &--01,&--03,{
        transform:rotate(180deg);
        img{
          transform:rotate(180deg);
        }
      }
    }
  }

  &_about{
    padding: 0 8.33vw;
    margin-bottom: 0;
    @include m.mq('middle') {
      padding: 0 20px;
      margin-bottom: 30px;
    }
    &_container{
      max-width: 66.66%;
      @include m.mq('middle') {
        max-width: 100%;
      }
    }
    &_heading{
      font-size: 7rem;
      font-weight: bold;
      line-height: 1.42;
      color: #3AA1D5;
      margin-bottom: 55px;
      @include m.mq('middle') {
        font-size: 2.4rem;
        line-height: 1.5;
        margin-bottom: 15px;
      }
    }
    &_txt{
      font-size: 1.8rem;
      font-weight: bold;
      color: #3AA1D5;
      line-height: 2.55;
      margin-bottom: 40px;
      @include m.mq('middle') {
        font-size: 1.4rem;
        line-height: 1.8;
        margin-bottom: 20px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .top_recruit{
    padding-top: 0;
    margin-top: -75px;
    margin-bottom: 175px;
    @include m.mq('middle') {
      margin-top: -15px;
      margin-bottom: 45px;
    }
    &_container{
      max-width: 100%;
      padding-right: 30px;
      @include m.mq('middle') {
        padding: 0;
      }
    }
    &_flex{
      justify-content: flex-end;
    }
    &_thumb{
      width: 250px;
      @include m.mq('middle') {
        width: 120px;
      }
    }
  }

  &_guideline{
    position: relative;
    padding: 0 8.33vw;
    @include m.mq('middle') {
      padding: 0 20px;
    }
    &_container{
    }
    &_list{
      &_item{
        margin-bottom: 20px;
        border: 1px solid #C4C4C4;
        border-radius: 10px;
        @include m.mq('middle') {
          margin-bottom: 15px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        &_head{
          position: relative;
          font-size: 2rem;
          font-weight: bold;
          color: #3AA1D5;
          padding: 15px 60px 15px 30px;
          cursor: pointer;
          @include m.mq('middle') {
            padding: 15px 45px 15px 15px;
            font-size: 1.5rem;
          }
          &:after{
            content: "";
            display: inline-block;
            width: 25px;
            height: 25px;
            background: url(./assets/img/icon_arrow_down_white.webp) no-repeat center center;
            background-size: cover;
            position: absolute;
            top: 0;
            right: 30px;
            bottom: 0;
            margin: auto;
            transition: all 0.2s ease-in-out;
            @include m.mq('middle') {
              right: 15px;
              width: 20px;
              height: 20px;
            }
          }
          &.active{
            &:after{
              transform:rotate(180deg);
              transition: all 0.2s ease-in-out;
            }
          }
        }
        &_contents{
          display: none;
          padding: 0 25px 50px 25px;
          @include m.mq('middle') {
            padding: 0 15px 30px 15px;
          }
          &_inner{
            border-top: 3px solid #3AA1D5;
            padding-top: 20px;
            @include m.mq('middle') {
              border-top: 2px solid #3AA1D5;
              padding-top: 15px;
            }
          }
        }
        &_catch{
          font-weight: bold;
          font-size: 1.8rem;
          margin-bottom: 20px;
          @include m.mq('middle') {
            font-size: 1.4rem;
            margin-bottom: 15px;
          }
        }
        &_table{
          margin-bottom: 0;
          @include m.mq('middle') {
            display: block;
          }
          tr{
            border-bottom: 1px solid #CCCCCC;
            @include m.mq('middle') {
              display: block;
            }
            &:first-child{
              border-top: 1px solid #CCCCCC;
            }
            th{
              width: 250px;
              text-align: left;
              color: #3AA1D5;
              padding: 20px 0;
              font-weight: bold;
              @include m.mq('middle') {
                width: 100%;
                display: block;
                padding: 15px 0 0;
              }
            }
            td{
              text-align: left;
              padding: 20px 0;
              @include m.mq('middle') {
                display: block;
                padding: 0 0 15px 0;
              }
            }
          }
        }
      }
    }
  }

}
