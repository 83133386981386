@use "./setting/setting" as m;
@use "variable" as var;

// html{

// }
/* -------------------- WordPress -------------------- */
.aligncenter{
  display: block;
  margin: 0 auto;
}
.alignright{
  float: right;
}
.alignleft{
  float: left;
}

body{
  overflow-x: hidden;
}
main{
  overflow-x: hidden;
}

.pc-none{
  @include m.mq('large') {
    display: none;
  }
}
.sp-none{
  @include m.mq('middle') {
    display: none;
  }
}


.btn-ptn-01{
  position: relative;
  z-index: 11;
  font-weight: bold;
  a{
    position: relative;
    display: inline-block;
    width: 296px;
    background: #fff;
    padding: 11px 12px 9px 12px;
    border-radius: 30px;
    box-shadow: 0px 6px 6px rgba(0,0,0,0.3);
    transition: all 0.2s ease-in-out;
    text-align: center;
    @include m.mq('middle') {
      padding: 16px 12px 14px 12px;
      width: 260px;
    }
    &:after{
      content: "";
      display: inline-block;
      width: 12.5px;
      height: 11.5px;
      background: url(./assets/img/icon_arrow_right_black.webp) no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      margin: auto;
      transition: all 0.2s ease-in-out;
    }
    &:hover{
      opacity: 1;
      box-shadow: 0px 6px 6px rgba(0,0,0,0.15);
      transition: all 0.2s ease-in-out;
      &:after{
        right: 15px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &--center{
    text-align: center;
  }
  &--large{
    font-size: 2.1rem;
    @include m.mq('middle') {
      font-size: 1.6rem;
    }
    a{
      width: 450px;
      padding: 11px 12px 9px 12px;
      border-radius: 35px;
      @include m.mq('middle') {
        width: 260px;
      }
      &:after{
        width: 18px;
        height: 17px;
        right: 25px;
      }
    }
  }
  &--border{
    a{
      border: 1px solid #E5E5E5;
    }
  }
  &--border-blue{
    a{
      border: 1px solid #3AA1D5;
    }
  }
  &--no-shadow{
    a{
      box-shadow: none;
    }
  }
}

.btn-ptn-02{
  position: relative;
  z-index: 11;
  &--center{
    text-align: center;
  }
  a{
    position: relative;
    display: inline-block;
    width: 296px;
    background: #3AA1D5;
    color: #fff;
    padding: 3px 12px 4px 12px;
    border-radius: 30px;
    border: 1px solid #E5E5E5;
    transition: all 0.2s ease-in-out;
    text-align: center;
    font-size: 2rem;
    @include m.mq('middle') {
      padding: 8px 12px 9px 12px;
      width: 260px;
    }
    &:hover{
      opacity: 1;
      box-shadow: 0px 6px 6px rgba(0,0,0,0.15);
      transition: all 0.2s ease-in-out;
      &:after{
        right: 15px;
        transition: all 0.2s ease-in-out;
      }
    }
    span{
      font-size: 1.4rem;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      bottom: 1px;
      margin-right: 18px;
    }
  }
}

.cmp-head-01{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 100px;
  @include m.mq('middle') {
    display: block;
    margin-bottom: 30px;
  }
  &_heading{
    line-height: 1.1;
    margin: 0;
    &_ja{
      position: relative;
      font-size: 2.2rem;
      display: block;
      font-weight: bold;
      @include m.mq('middle') {
        font-size: 1.8rem;
        margin-bottom: 5px;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 60px;
        height: 1px;
        background: #3AA1D5;
        vertical-align: middle;
        margin-right: 15px;
        position: relative;
        bottom: 2px;
        @include m.mq('middle') {
          margin-right: 10px;
          width: 40px;
        }
      }
    }
    &_en{
      font-size: 8rem;
      font-family: var.$font-en;
      font-weight: 800;
      letter-spacing: 0.1em;
      color: #3AA1D5;
      display: block;
      @include m.mq('middle') {
        font-size: 4.2rem;
        letter-spacing: 0.1em;
      }
    }
  }
  &_txt{
    margin: 0;
    padding: 0 0 8px 60px;
    font-weight: bold;
    @include m.mq('middle') {
      font-size: 1.2rem;
      padding: 0;
      margin-top: 10px;
    }
  }
  .btn-ptn-01{
    margin-bottom: 10px;
    @include m.mq('middle') {
    }
  }
  &_cats{
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    line-height: 1.3;
    margin-left: 30px;
    position: relative;
    font-weight: bold;
    @include m.mq('middle') {
      margin: 15px 0 0 0;
      font-size: 1rem;
    }
    &_item{
      background: #C0D9F2;
      border-radius: 15px;
      margin-right: 10px;
      margin-bottom: 10px;
      transition: all 0.2s ease-in-out;
      @include m.mq('middle') {
        margin-right: 5px;
        margin-bottom: 5px;
      }
      &.active{
        background: #3AA1D5;
        transition: all 0.2s ease-in-out;
        a{
          color: #fff;
          transition: all 0.2s ease-in-out;
        }
      }
      a{
        display: block;
        padding: 3px 15px;
        transition: all 0.2s ease-in-out;
        @include m.mq('middle') {
          padding: 5px 15px;
        }
      }
      &:hover{
        background: #3AA1D5;
        transition: all 0.2s ease-in-out;
        a{
          color: #fff;
          transition: all 0.2s ease-in-out;
          opacity: 1;
        }
      }
    }
  }
}

.lower{
  background: #EBF5FF;
  padding-bottom: 12.5vw;
  @include m.mq('middle') {
    padding-bottom: 30px;
  }
}

.lower-head{
  position: relative;
  background: #fff;
  &_curtain-01{
    display: block;
    width: 0;
    height: 100%;
    background: #f5f5f5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  &_curtain-02{
    display: block;
    width: 0;
    height: 100%;
    background: #f5f5f5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  &_image{
    margin: 0;
    opacity: 0;
    img {
      width: 100%;
      height: 42.5vw;
      object-fit: cover; /* 画像がコンテナのサイズをカバーするようにする */
      object-position: center; /* 画像の位置を中央にする */
    }
  }
  &_heading{
    padding-left: 8.33vw;
    position: absolute;
    left: 0;
    bottom: 10.41vw;
    margin: 0;
    color: #fff;
    line-height: 1;
    @include m.mq('middle') {
      padding-left: 0;
      left: 30px;
      bottom: 45px;
    }
    &_ja{
      font-size: 2.66vw;
      display: inline-block;
      @include m.mq('middle') {
        font-size: 2.1rem;
      }
    }
    &_en{
      font-size: 2.5vw;
      font-family: var.$font-en;
      font-weight: bold;
      letter-spacing: 0.1em;
      display: inline-block;
      @include m.mq('middle') {
        font-size: 2rem;
      }
    }
  }
}

.lower-flex{
  display: flex;
  min-height: 100vw;
  justify-content: flex-end;
  &_head{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #EBF5FF;
    width: 8.33vw;
    height: 100vh;
    //padding-top: 22.5vw;
    @include m.mq('middle') {
      width: 30px;
    }
    &_inner{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.fixed{
      position: fixed;
      left: 0;
      top: 0;
      transition: all 0.5s ease-in-out;
    }
    &.none{
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }
    &_txt{
      font-size: 0.83vw;
      margin-bottom: 0;
      width: 10px;
      //height: 300px;
      font-weight: bold;
      transform: rotate(90deg);
      transform-origin: center center;
      color: #3AA1D5;
      white-space: nowrap;
      position: relative;
      //left: 1.17vw;
      width: 100%;
      height: auto;
      @include m.mq('middle') {
        font-size: 0.8rem;
      }
      &_ja{
      }
      &_en{
        font-family: var.$font-en;
        font-weight: bold;
        letter-spacing: 0.1em;
      }
    }
  }
  &_contents{
    width: 91.66vw;
    background: #fff;
    border-radius: 8.33vw 0 0 8.33vw;
    margin-top: -8.33vw;
    position: relative;
    z-index: 1;
    padding-left: 8.33vw;
    padding-bottom: 8.33vw;
    @include m.mq('middle') {
      border-radius: 30px 0 0 30px;
      width: calc(100% - 30px);
      padding: 0 20px 30px;
      margin-top: -30px;
    }
    &--recruit{
      position: relative;
      padding-left: 0;
      overflow: hidden;
      #breadcrumb{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
    &_container{
      opacity: 0;
    }
    &_inner{
      padding-right: 8.33vw;
      @include m.mq('middle') {
        padding: 0;
      }
    }
  }
}

#breadcrumb{
  padding: 2.08vw 4.16vw;
  margin-bottom: 45px;
  font-weight: bold;
  @include m.mq('middle') {
    padding: 20px 0;
    margin-bottom: 15px;
  }
  .bread-container{
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    @include m.mq('middle') {
      font-size: 0.9rem;
    }
  }
  a{
    &:after{
      content: "-";
      margin: 0 5px;
    }
  }
}

.heading-ptn-01{
  color: #C0D9F2;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  @include m.mq('middle') {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  &:before{
    content: "";
    display: inline-block;
    width: 60px;
    height: 1px;
    background: #333333;
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    margin-right: 20px;
    @include m.mq('middle') {
      width: 40px;
      margin-right: 10px;
    }
  }
}

.heading-ptn-02{
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 40px;
  @include m.mq('middle') {
    font-size: 2.2rem;
    margin-bottom: 25px;
  }
  &--en{
    color: #3AA1D5;
    font-family: var.$font-en;
    letter-spacing: 0.1em;
    font-size: 6rem;
    line-height: 1;
    margin-bottom: 55px;
    font-weight: 800;
    @include m.mq('middle') {
      font-size: 3rem;
      margin-bottom: 25px;
      letter-spacing: 0.05em;
    }
  }
}

.scrollable-table {
  overflow-x: auto; /* 横方向にスクロールを許可 */
  white-space: nowrap; /* コンテンツを折り返さない */
}

.scrollable-table table {
  min-width: 100%; /* テーブルの最小幅を100%に設定 */
  table-layout: auto !important;
  /* 必要に応じてテーブルの幅を調整 */
}
