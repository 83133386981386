@use "./setting/setting" as m;
@use "variable" as var;

.post-archive{
  display: flex;
  flex-wrap: wrap;
  &_item{
    @include m.mq('large') {
      width: 29.4%;
      margin-right: 5.9%;
      margin-bottom: 50px;
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include m.mq('middle') {
      width: 49%;
      margin-right: 2%;
      margin-bottom: 5%;
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    a{
      display: block;
    }
    &_thumb{
      text-align: center;
      margin-bottom: 15px;
      aspect-ratio: 482 / 360;
      @include m.mq('middle') {
        margin-bottom: 10px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &_info{
      display: flex;
      // align-items: center;
      // margin-bottom: 10px;
      flex-direction: column;
      @include m.mq('middle') {
        display: block;
      }
      &_time{
        color: #B4B4B4;
        margin-right: 10px;
        
        font-weight: bold;
        @include m.mq('middle') {
          margin-right: 10px;
        }
      }
      &_cats{
        line-height: 1.3;
        font-weight: bold;
        font-size: 1.1rem;
        display: flex;
        flex-wrap: wrap;
        @include m.mq('middle') {
          font-size: 0.9rem;
          display: flex;
          margin-top: 5px;
        }
        &_item{
          background: #C0D9F2;
          padding: 5px 10px;
          border-radius: 15px;
          margin-right: 5px;
          margin-bottom: 5px;
          white-space: nowrap;
          @include m.mq('middle') {
            padding: 5px 10px;
            border-radius: 13px;
          }
        }
      }
    }
    &_heading{
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.66;
      margin-bottom: 15px;
      @include m.mq('middle') {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
    &_txt{
      margin: 0;
      line-height: 1.6;
      @include m.mq('middle') {
        font-size: 1rem;
      }
    }
  }
}

.pagination{
  font-size: 1.8rem;
  margin-top: 15px;
  @include m.mq('middle') {
    font-size: 1.6rem;
    margin-top: 5px;
  }
  &_container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a,span{
    display: inline-block;
    margin: 0 5px;
    padding: 0 5px;
    font-family: var.$font-en;
    font-weight: 500;
    @include m.mq('middle') {
      margin: 0 3px;
    }
  }
  span{
    color: #3AA1D5;
    border-bottom: 2px solid #3AA1D5;
    &.dots{
      border-bottom: none;
      color: #333;
    }
  }
}

.cats-list{
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  line-height: 1.7;
  margin-bottom: 20px;
  @include m.mq('middle') {
    margin-bottom: 10px;
  }
  &:last-child{
    margin-bottom: 90px;
    @include m.mq('middle') {
      margin-bottom: 15px;
    }
  }
  &_item{
    min-width: 83px;
    text-align: center;
    background: #C0D9F2;
    border-radius: 13px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 1.3rem;
    @include m.mq('middle') {
      min-width: 50px;
      font-size: 0.9rem;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    &.child{
      background: #EBF5FF;
    }
    &.active{
      background: #3AA1D5;
      a{
        color: #fff;
      }
    }
    a{
      display: block;
      padding: 2px 15px;
      @include m.mq('middle') {
        padding: 2px 10px 3px;
      }
    }
  }
}

.news-single{
  .post-archive_item_info{
    margin-bottom: 45px;
    @include m.mq('middle') {
      display: flex;
      margin-bottom: 15px;
    }
  }
  &_heading{
    font-size: 3rem;
    font-weight: bold;
    border-bottom: 1px solid #3AA1D5;
    line-height: 1.66;
    padding-bottom: 20px;
    margin-bottom: 40px;
    @include m.mq('middle') {
      font-size: 2rem;
      line-height: 1.4;
      padding-bottom: 15px;
      margin-bottom: 20px;
    }
  }
  &_contents{
    margin-bottom: 75px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
    h1,h2{
      font-size: 2.4rem;
      background: #EBF5FF;
      padding: 10px 30px;
      line-height: 1.5;
      border-bottom: 2px solid #3AA1D5;
      font-weight: bold;
      margin-bottom: 40px;
      @include m.mq('middle') {
        padding: 10px 15px;
        font-size: 2rem;
        margin-bottom: 20px;
      }
    }
    h3{
      position: relative;
      color: #3AA1D5;
      font-size: 2rem;
      font-weight: bold;
      padding-left: 80px;
      margin-bottom: 20px;
      @include m.mq('middle') {
        font-size: 1.8rem;
        padding-left: 40px;
        margin-bottom: 15px;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 60px;
        height: 1px;
        background: #333333;
        vertical-align: middle;
        position: relative;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        @include m.mq('middle') {
          width: 30px;
        }
      }
    }
  }

}
