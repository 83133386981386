@use "../setting/setting" as m;
@use "variable" as var;

.contact{

  &_catch{
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 45px;
    @include m.mq('middle') {
      font-size: 1.6rem;
      margin-bottom: 30px;
    }
  }

  &_menu{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-weight: bold;
    margin-bottom: 155px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    &_item{
      margin-right: 15px;
      margin-bottom: 15px;
      width: 250px;
      border: 1px solid #E5E5E5;
      border-radius: 50px;
      @include m.mq('middle') {
        width: 100%;
        margin: 0 0 10px 0;
        font-size: 1.2rem;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      a{
        position: relative;
        display: block;
        padding: 5px 20px;
        @include m.mq('middle') {
          padding: 10px 20px;
        }
        &:after{
          content: "";
          display: inline-block;
          width: 25px;
          height: 25px;
          background: url(./assets/img/icon_arrow_down_white.webp) no-repeat center center;
          background-size: cover;
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }

  &_tel{
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    border: 2px solid #3AA1D5;
    border-radius: 10px;
    padding: 0 20px 20px;
    margin-bottom: 145px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
    &_heading{
      font-size: 2.4rem;
      font-weight: bold;
      color: #3AA1D5;
      margin: -20px 0 20px;
      @include m.mq('middle') {
        font-size: 1.7rem;
        margin: -13px 0 20px;
      }
      span{
        display: inline-block;
        background: #fff;
        padding: 0 20px;
        @include m.mq('middle') {
          padding: 0 10px;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url(./assets/img/icon_tel_bluewhite.webp) no-repeat center center;
          background-size: cover;
          vertical-align: middle;
          position: relative;
          bottom: 2px;
          margin-right: 10px;
          @include m.mq('middle') {
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
        }
      }
    }
    &_link{
      font-family: var.$font-en;
      font-size: 4rem;
      font-weight: bold;
      color: #3AA1D5;
      line-height: 1;
      margin-bottom: 20px;
      display: inline-block;
      @include m.mq('middle') {
        font-size: 3rem;
        margin-bottom: 15px;
      }
    }
    &_txt{
      margin: 0;
    }
  }

  &_sec{
    margin-bottom: 110px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &_heading{
      font-size: 2.4rem;
      font-weight: bold;
      color: #3AA1D5;
      margin-bottom: 30px;
      @include m.mq('middle') {
        font-size: 2rem;
        margin-bottom: 20px;
      }
    }
    &_inner{
      max-width: 88.88%;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
      }
    }
    label{
      font-weight: 500;
      margin-bottom: 0;
    }
    .policy-check{
      text-align: center;
      margin-bottom: 40px;
      @include m.mq('middle') {
        margin-bottom: 20px;
        font-size: 1.2rem;
      }
      a{
        color: #3AA1D5;
        text-decoration: underline;
      }
    }
    .btn-submit{
      width: 450px;
      background: #3AA1D5;
      color: #fff;
      display: block;
      margin: 0 auto;
      font-size: 2rem;
      border-radius: 45px;
      padding: 25px;
      @include m.mq('middle') {
        width: 200px;
        font-size: 1.6rem;
        padding: 20px;
        border-radius: 30px;
      }
    }
    &_table{
      width: 100%;
      margin-bottom: 40px;
      @include m.mq('middle') {
        display: block;
        margin-bottom: 20px;
      }
      p{
        margin: 0;
      }
      input[type="text"],input[type="email"],input[type="tel"],textarea{
        margin: 0;
        padding: 20px;
        border-radius: 10px;
        box-shadow: none;
        //display: inherit;
        @include m.mq('middle') {
          padding: 15px;
        }
        &::placeholder{
          color: #C4C4C4;
        }
      }
      input[type="text"]{
        display: inherit;
      }
      .w-200{
        display: inline-block !important;
        width: 200px;
        margin: 0 15px 15px 0;
      }
      .w-250{
        display: inline-block !important;
        width: 250px;
      }
      .memo{
        font-size: 1.2rem;
        margin-left: 15px;
        @include m.mq('middle') {
          display: block;
          line-height: 1;
          font-size: 1.1rem;
          margin: 15px 0 0 0;
        }
      }
      .p-street-address{
        margin-top: 15px !important;
        @include m.mq('middle') {
          margin-top: 0 !important;
        }
      }
      .hissu{
        display: inline-block;
        color: #fff;
        background: #FF0000;
        font-weight: 500;
        font-size: 1.2rem;
        padding: 5px 10px;
        border-radius: 15px;
        line-height: 1;
        margin-left: 10px;
        position: relative;
        bottom: 1px;
      }
      .note{
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
      }
      tr{
        border-bottom: 1px solid #C4C4C4;
        @include m.mq('middle') {
          display: block;
        }
        &:first-child{
          border-top: 1px solid #C4C4C4;
        }
        th{
          position: relative;
          border-bottom: none;
          text-align: left;
          padding: 45px 0 0;
          width: 27.5%;
          vertical-align: top;
          @include m.mq('middle') {
            display: block;
            width: 100%;
            padding: 15px 0 10px;
          }
        }
        td{
          border-bottom: none;
          padding: 30px 0;
          @include m.mq('middle') {
            width: 100%;
            padding: 5px 0 15px;
            display: block;
          }
          a{
            font-weight: bold;
          }
        }
        td.your-sex-btn{
          p{
            height: 61.500px;
            position: relative;
            @include m.mq('middle') {
             height: 50.5px;
            }
            span.wpcf7-form-control-wrap{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);

              .wpcf7-list-item{
                margin-left: 0;
                margin-right: 45px;
              }
            }
          }
        }
        td.birth-select{
          p{
            height: 61.500px;
            @include m.mq('middle') {
             height: 50.5px;
            }
            display: flex;
            align-items: center;
            select{
              margin-bottom: 0;
              margin-right: 5px;
              width: 130px;
              @include m.mq('mobile') {
                width: 60px;
               }
            }

            span{
              &:not(:nth-of-type(1)){
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    .zumen{
      margin-bottom: 0;
      display: block;
      .wpcf7-list-item{
        margin: 0 45px 15px 0;
      }
    }
    .zumen-area{
      margin-bottom: 15px;
      br{
        display: none;
      }
      .wpcf7-file{
        //padding: 10px 0;
        padding: 10px 0 10px 0;
      }
    }
  }

}
