@use "./setting/setting" as m;
@use "variables" as var;

/* =================== html body ============================= */
html
{
  box-sizing: border-box;
  color: var.$base-font-color;
  font-family: var.$font-gothic;
  font-size: 62.5%;
  line-height: var.$base-line-height;//1.8
  height: -webkit-fill-available;
}

body
{
  font-size: var.$base-font-size;//14px
  width: 100%;
  font-weight: 500;
  height: -webkit-fill-available;
  @include m.mq('middle') {
    line-height: 1.8;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
  }
}

html,
body
{
  height: 100%;
}
/* =========================================================== */

/* =================== All element =========================== */
*
{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after
{
  box-sizing: inherit;
}
/* =========================================================== */


/* =================== h ===================================== */
h1,
h2,
h3,
h4,
h5,
h6
{
  font-family: var.$font-gothic;
  font-weight: 600;
  line-height: var.$heading-line-height;//1.2
  @include m.mq('middle') {
    line-height: 1.4;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
  }
}
/* =========================================================== */

/* =================== a ===================================== */
a
{
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:focus
{
  color: var.$base-action-color;
}
a:hover
{
  opacity: 0.75;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/* =========================================================== */


/* =================== list ================================== */
ul,
ol
{
  list-style-type: none;
}

dt
{
  font-weight: 600;
}
/* =========================================================== */

/* =================== media ================================= */
img,
picture
{
  max-width: 100%;
}
img
{
  height: auto;
  vertical-align: top;
  line-height: 1;
}
/* =========================================================== */

/* =================== table ================================= */
table
{
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0px;
  width: 100%;
}
/* =========================================================== */

/* =================== form label ============================ */
form label
{
  cursor: pointer;
}

fieldset
{
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend
{
  font-weight: 600;
  margin-bottom: calc(var.$small-spacing / 2);
  padding: 0;
}

label
{
  display: block;
  font-weight: 600;
  margin-bottom: calc(var.$small-spacing / 2);
}

input,
select,
textarea
{
  display: block;
  font-size: 16px;
}

[type=color], [type=date],
[type=datetime], [type=datetime-local],
[type=email], [type=month],
[type=number], [type=password],
[type=search], [type=tel],
[type=text], [type=time],
[type=url], [type=week],
input:not([type]), textarea
{
  appearance: none;
  background-color: var.$base-background-color;
  border: var.$base-border;
  border-radius: var.$base-border-radius;
  box-shadow: var.$form-box-shadow;
  margin-bottom: var.$small-spacing;
  padding: calc(var.$base-spacing / 3);
  transition: border-color var.$base-duration var.$base-timing;
  width: 100%;
  &:hover {
    background-color: lighten(var.$base-border-color, 10%);
  }

  &:focus {
    border-color: var.$base-action-color;
    box-shadow: var.$form-box-shadow-focus;
    outline: none;
  }
}



textarea
{
  resize: vertical;
}

[type="checkbox"],
[type="radio"]
{
  display: inline;
  margin-right: calc(var.$small-spacing / 2);
}

[type="file"]
{
  margin-bottom: var.$small-spacing;
  width: 100%;
}

select
{
  margin-bottom: var.$small-spacing;
  width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select
{
  &:focus {
    //outline: var.$focus-outline;
    //outline-offset: var.$focus-outline-offset;
  }
}



/* =========================================================== */


/* ==================== button =============================== */
button,
[type = "submit"],
[type = "reset"],
[type = "button"]
{
  appearance: none;
  background-color: var.$base-action-color;
  border: 0;
  border-radius: var.$base-border-radius;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: var.$small-spacing var.$base-spacing;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  transition: background-color var.$base-duration var.$base-timing;

  &:hover {
    background-color: darken(var.$base-action-color, 5%);
  }

  &:focus {
    /*
    outline: var.$focus-outline;
    outline-offset: var.$focus-outline-offset;
    */
  }


}
/* =========================================================== */
