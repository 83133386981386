/* ------------ breakpoints ---------------- */
$breakpoint: (
  mobile: 'screen and (max-width: 599px)',
  middle: 'screen and (max-width: 979px)',
  large: 'screen and (min-width: 980px)',
);

@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}
