@use "./setting/setting" as m;
@use "variable" as var;

.footer{
  background: #F1F1F1;
  position: relative;
  z-index: 1;
  &_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    @include m.mq('middle') {
      display: block;
    }
  }
  &_left{
    padding: 70px 0 50px;
    width: 50%;
    background: #484B50;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    @include m.mq('middle') {
      padding: 40px 20px 25px;
      width: 100%;
    }
    &_inner{
      width: 500px;
      padding-left: 35px;
      @include m.mq('middle') {
        width: 100%;
        padding: 0;
      }
    }
  }
  &_right{
    padding: 70px 0 50px;
    width: 50%;
    background: #F1F1F1;
    @include m.mq('middle') {
      padding: 40px 20px;
      width: 100%;
    }
    &_inner{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  &_logo{
    margin-bottom: 30px;
    @include m.mq('middle') {
      margin-bottom: 20px;
      img{
        width: 240px;
      }
    }
  }
  &_navi{
    font-weight: bold;
    @include m.mq('middle') {
      font-size: 1.2rem;
    }
    &_home{
      margin-bottom: 20px;
      @include m.mq('middle') {
        margin-bottom: 15px;
      }
    }
    &_flex{
      display: flex;
    }
    &_list{
      margin-right: 35px;
      &:last-child{
        margin-right: 0;
      }
      &_item{
        margin-bottom: 18px;
      }
    }
  }
  &_copy{
    font-size: 1rem;
    @include m.mq('middle') {
      font-size: 0.9rem;
    }
  }
  &_contact{
    &_heading{
      font-size: 1.2rem;
      margin-bottom: 30px;
      font-weight: bold;
      @include m.mq('middle') {
        text-align: center;
        font-size: 1.3rem;
        margin-bottom: 20px;
      }
      span{
        border-bottom: 2px dashed #707070;
        display: inline-block;
      }
    }
    &_btns{
      li{
        margin-bottom: 30px;
        @include m.mq('middle') {
          margin-bottom: 15px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}
