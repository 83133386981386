@use "./setting/setting" as m;
@use "variable" as var;

@keyframes scrollAnimation {
  0% {
    top: -20px;
  }
  100% {
    top: 80px; /* 白い線の高さと同じに設定 */
  }
}

.top{

  &_slider{
    overflow: hidden;
    margin: 0;
    position: relative;
    &_catch{
      position: absolute;
      top: 50%;
      left: 4.16%;
      transform: translateY(-50%);
      margin: 0;
      z-index: 1;
      @include m.mq('middle') {
        width: 68%;
      }
      img{
        transform: translateY(20px);
        opacity: 0;
      }
    }
    &_scroll{
      width: 12px;
      position: relative;
      color: #fff;
      position: absolute;
      left: 50px;
      bottom: 0px;
      z-index: 1;
      @include m.mq('middle') {
        left: 20px;
      }
      &_line{
        overflow: hidden;
        position: relative;
        content: "";
        display: block;
        width: 1px;
        height: 80px;
        background: #fff;
        margin-left: 6px;
        margin-top: 8px;
        &_blue{
          content: "";
          width: 1px;
          height: 20px;
          display: block;
          background: #3AA1D5;
          position: absolute;
          left:0;
          top: 0;
          animation: scrollAnimation 1.5s ease infinite;
        }
      }
    }
    &_list{
      width: 100vw;
      height: 100vh;
      margin-bottom: 0 !important;
      &_item {
        width: 100%;
        height: 100%;
        transition: transform 3s ease-in-out;
        img {
          width: 100%;
          height: 100vh;
          object-fit: cover; /* 画像がコンテナのサイズをカバーするようにする */
          object-position: center; /* 画像の位置を中央にする */
        }
      }
    }
    .slick-dots{
      width: auto;
      height: auto;
      position: absolute;
      right: 25px;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 3333;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 80px;
      @include m.mq('middle') {
        flex-direction: row;
        right: 20px;
        bottom: 20px;
        top: inherit;
      }
      li{
        width: 10px;
        height: 10px;
        margin: 10px 0;
        @include m.mq('middle') {
          width: 6px;
          height: 6px;
          margin: 0 4px;
        }
        button{
          background: #fff;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          padding: 0;
          @include m.mq('middle') {
            width: 6px;
            height: 6px;
          }
          &:before{
            width: 10px;
            height: 10px;
            content: "";
            @include m.mq('middle') {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }
  }

  &_about{
    position: relative;
    /* Permalink - use to edit and share this gradient. パーマリンク - このグラデーションの編集や共有に使用します: https://colorzilla.com/gradient-editor/#c0d9f2+0,3aa1d5+100 */
    background: -webkit-linear-gradient(top left, #c0d9f2 0%, #3aa1d5 100%);
    background: -o-linear-gradient(top left, #c0d9f2 0%, #3aa1d5 100%);
    background: linear-gradient(to bottom right, #c0d9f2 0%, #3aa1d5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-radius: 0 0 100px 0;
    overflow: hidden;
    /*
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    */
    @include m.mq('middle') {
      border-radius: 0 0 50px 0;
    }
    &_container{
      padding: 110px 0 145px;
      @include m.mq('middle') {
        padding: 60px 20px 75px;
      }
    }
    &_txt{
      font-size: 1.8rem;
      line-height: 2;
      color: #fff;
      max-width: 600px;
      margin: 0 auto 3.75vw;
      font-weight: bold;
      @include m.mq('middle') {
        font-size: 1.6rem;
        line-height: 1.8;
        margin: 0 auto 30px;
      }
    }
    &_slider{
      position: absolute !important;
      width: 100%;
      left: 0;
      bottom: 0;
      line-height: 0;
      mix-blend-mode: overlay;
      position: relative;
      z-index: 1;
      margin: 0;
      overflow: hidden;
      .slick-track{
      }
      .slick-slide{
      }
      &_item{
        margin-right: 150px;
        @include m.mq('middle') {
          margin-right: 75px;
        }
        img{
          max-width: none;
          width: 1632px; // 画像の幅
          @include m.mq('middle') {
            width: 816px; // 画像の幅
          }
        }
      }
    }
  }

  &_service{
    padding: 8.33vw 0 0;
    margin-bottom: 150px;
    @include m.mq('middle') {
      padding: 60px 20px 0;
      margin-bottom: 75px;
    }
    &_container{
      max-width: var.$content-width;
      margin: 0 auto;
    }
    &_col-03{
      display: flex;
      flex-wrap: wrap;
      @include m.mq('middle') {
        display: block;
        margin-bottom: 10px;
      }
      &_item{
        position: relative;
        width: 33.33vw;
        height: 33.33vw;
        text-align: center;
        border-radius: 60px 0 0 0;
        margin-bottom: 55px;
        @include m.mq('middle') {
          width: 100%;
          height: 89.33vw;
          border-radius: 30px 0 0 0;
          margin-bottom: 30px;
        }
        &_thumb-container {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: hidden; // ここにoverflow:hiddenを適用
          border-radius: 60px 0 0 0; // 親と同じborder-radiusを適用
          z-index: -1;
        }
        &_thumb{
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.5s ease-out;
        }
        a{
          color: #fff;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
          @include m.mq('middle') {
          }
          &:before{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 110;
            border-radius: 60px 0 0 0; // 親と同じborder-radiusを適用
            background: rgba(0, 0, 0, 0); // 修正した透明度
            transition: background-color 0.5s ease-out; // transitionは変化するプロパティに適用
          }
          &:hover{
            opacity: 1;
          }
        }
        &.overlay{
          a{
            &:before{
              content: "";
             background: rgba(0, 0, 0, 0.4); // 背景色の変化を設定
             transition: background-color 0.5s ease-out; // transitionは変化するプロパティに適用
             @include m.mq('middle') {
               background: rgba(0, 0, 0, 0); // 背景色の変化を設定
             }
            }
          }
        }
        &:hover{
          .top_service_col-03_item_thumb{
            transform: scale(1.04);
            transition: transform 0.5s ease-out;
          }
        }
        &_num{
          width: 109px;
          position: absolute;
          left: 20px;
          top: -55px;
          line-height: 1;
          @include m.mq('middle') {
            width: 64px;
            left: 5px;
            top: -25px;
          }
        }
        &_en{
          font-family: var.$font-en;
          font-weight: 800;
          margin-bottom: 10px;
          letter-spacing: 0.1em;
        }
        &_ja{
          font-size: 4.5rem;
          margin-bottom: 10px;
          font-weight: bold;
          @include m.mq('middle') {
            font-size: 2.8rem;
          }
        }
        &_txt{
          margin: 0;
          font-weight: bold;
        }
      }
    }
    &_special{
      position: relative;
      &:before{
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        display: block;
        width: 100%;
        height: 234px;
        background: url(./assets/img/top_service_special_bg.webp) no-repeat center center;
        background-size: cover;
        @include m.mq('middle') {}
      }
      &_container{
        position: relative;
        z-index: 1;
        max-width: var.$content-width;
        margin: 0 auto;
        padding-top: 150px;
      }
      &_box{
        position: relative;
        display: flex;
        justify-content: flex-start;
        //flex-direction: row-reverse;
        border-bottom: 1px solid #3AA1D5;
        background: #fff;
        padding: 30px 30px 90px;
        margin-bottom: -29px;
        @include m.mq('middle') {
          display: block;
          padding: 30px 20px 60px;
        }
        &_thumb{
          position: absolute;
          right: 20px;
          top: -80px;
          @include m.mq('middle') {
            position: static;
            margin-top: -160px;
            text-align: center;
            padding: 0 15px;
            margin-bottom: 20px;
          }
        }
        &_heading{
          margin: 0;
          @include m.mq('middle') {
            text-align: center;
          }
          img{
            @include m.mq('middle') {
              width: 260px;
            }
          }
        }
      }
    }
    &_youtube{
      text-align: center;
      max-width: 800px;
      margin: 100px auto 0;
      @include m.mq('middle'){
        max-width: 100%;
        margin-top: 50px;
      }
      iframe{
        width: 100%;
        aspect-ratio: 16 / 9;
        margin-bottom: 20px;
        @include m.mq('middle'){
          margin-bottom: 10px;
        }
      }
      &_ttl{
        font-size: 1.4rem;
        display: inline-block;
        padding:5px 30px;
        background-color: #3AA1D5;
        border: 1px solid #E5E5E5;
        font-weight: bold;
        color: #fff;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
        @include m.mq('mobile'){
          font-size: 1rem;
          padding: 5px 10px;
        }

      }
    }
  }

  &_news{
    background: #EBF5FF;
    border-radius: 100px 0 0 0;
    padding: 100px 0 45px;
    @include m.mq('middle') {
      border-radius: 50px 0 0 0;
      padding: 60px 20px 45px;
    }
    &_container{
      max-width: var.$content-width;
      margin: 0 auto;
    }
    .btn-ptn-01{
      @include m.mq('middle') {
        margin-top: 15px;
      }
    }
    .mb-150{
      margin-bottom: 150px;
      @include m.mq('middle'){
        margin-bottom: 50px;
      }
    }
    .mb-100{
      @include m.mq('middle'){
        margin-bottom: 100px;
      }
    }
  }

  &_recruit{
    position: relative;
    overflow: hidden;
    padding: 30px 0 0;
    @include m.mq('middle') {
      padding: 30px 20px 0;
    }
    &_container{
      position: relative;
      max-width: var.$content-width;
      margin: 0 auto;
    }
    &_flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &_heading{
      margin: 0;
      width: 403px;
      padding-bottom: 65px;
      @include m.mq('middle') {
        width: 140px;
        padding-bottom: 25px;
      }
    }
    &_btn{
      width: 134px;
      height: 134px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      @include m.mq('middle') {
        width: 50px;
        height: 50px;
      }
    }
    &_thumb{
      margin: 0 30px 0 0;
      width: 213px;
      @include m.mq('middle') {
        width: 90px;
        margin: 0 0px 0 0;
      }
    }
    &_slider{
      position: absolute !important;
      width: 100%;
      height: 72px;
      line-height: 1;
      z-index: -1;
      margin: 0;
      overflow: hidden;
      @include m.mq('middle') {
        height: 36px;
      }
      .slick-track{
      }
      .slick-slide{
        margin: 0 125px;
        @include m.mq('middle') {
          margin: 0 60px;
        }
      }
      &--01{
        left: 0;
        bottom: 104px;
        @include m.mq('middle') {
          bottom: 52px;
        }
      }
      &--02{
        left: 0;
        bottom: -3px;
      }
      &_item{
        //margin-right: 150px;
        img{
          max-width: none;
          width: 2353px; // 画像の幅
          //height: 104px;
          @include m.mq('middle') {
            width: 1176px; // 画像の幅
          }
        }
      }
    }
  }

}
