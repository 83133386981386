@use "./setting/setting" as m;
@use "variable" as var;

.service{

  &_head{
    margin-bottom: 115px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
    &_heading{
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.7;
      margin-bottom: 45px;
      @include m.mq('middle') {
        font-size: 1.8rem;
        margin-bottom: 30px;
        line-height: 1.5;
        br{
          display: none;
        }
      }
    }
    &_menu{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-weight: bold;
      &_item{
        margin-right: 2.66%;
        margin-bottom: 2.66%;
        width: 23%;
        border: 1px solid #E5E5E5;
        border-radius: 50px;
        @include m.mq('middle') {
          width: 100%;
          margin: 0 0 10px 0;
          font-size: 1.2rem;
        }
        &:nth-child(4n){
          margin-right: 0;
        }
        a{
          position: relative;
          display: block;
          padding: 5px 20px;
          @include m.mq('middle') {
            padding: 10px 20px;
          }
          &:after{
            content: "";
            display: inline-block;
            width: 25px;
            height: 25px;
            background: url(./assets/img/icon_arrow_down_white.webp) no-repeat center center;
            background-size: cover;
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }

  &_sec{
    margin-bottom: 75px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    &--last{
      margin-bottom: 115px;
      @include m.mq('middle') {
        margin-bottom: 30px;
      }
    }
    &_heading{
      display: flex;
      align-items: center;
      margin: 0 0 30px;
      @include m.mq('middle') {
        margin: 0 0 5px;
      }
      &_num{
        display: inline-block;
        img{
          width: auto;
          height: 105px;
          @include m.mq('middle') {
            height: 60px;
          }
        }
      }
      &_line{
        content: "";
        display: inline-block;
        width: 60px;
        height: 1px;
        background: #333333;
        vertical-align: middle;
        margin: 0 20px 0 15px;
        position: relative;
        bottom: 2px;
        @include m.mq('middle') {
          width: 30px;
          margin: 0 10px 0 10px;
          bottom: 0px;
        }
      }
      &_txt{
        font-size: 3rem;
        font-weight: bold;
        @include m.mq('middle') {
          font-size: 2rem;
        }
      }
    }
    &_col-02{
      display: flex;
      @include m.mq('middle') {
        display: block;
      }
      &_txt{
        width: 270px;
        margin-right: 30px;
        font-weight: bold;
        @include m.mq('middle') {
          width: 100%;
          margin: 0 0 15px;
        }
      }
      &_thumb{
        flex: 1;
        margin: 0;
        @include m.mq('middle') {
          border-radius: 20px;
          img{
            border-radius: 20px;
          }
        }
      }
    }
    &--reverse{
      position: relative;
      right: 8.33vw;
      @include m.mq('middle') {
        right: 0;
      }
      .service_sec_heading{
        justify-content: flex-end;
        @include m.mq('middle') {
          //justify-content: flex-start;
        }
      }
      .service_sec_col-02{
        flex-direction: row-reverse;
        @include m.mq('middle') {
        }
        .service_sec_col-02_txt{
          margin: 0 0 0 30px;
          @include m.mq('middle') {
            margin: 0 0 15px;
          }
        }
      }
    }
  }

  &_bnr{
    text-align: center;
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
  }

  &_facilities{
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
    &_box{
      background: #EBF5FF;
      border-radius: 30px;
      padding: 50px 100px;
      //line-height: 2.28em;
      @include m.mq('middle') {
        border-radius: 20px;
        padding: 20px;
        font-size: 1.1rem;
      }
    }
    &_dl{
      border-bottom: 1px solid #CCCCCC;
      margin-bottom: 20px;
      padding-bottom: 20px;
      &_item{
        display: flex;
        dt{
          width: 50%;
          font-weight: 500;
          font-weight: bold;
          @include m.mq('middle') {
            width: 60%;
          }
        }
        dd{
          width: 50%;
          @include m.mq('middle') {
            flex: 1;
          }
        }
      }
    }
    &_txt{
      border-bottom: 1px solid #CCCCCC;
      margin-bottom: 20px;
      padding-bottom: 20px;
      span{
        font-weight: bold;
      }
      &:last-child{
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  &_works{
    &_time-table{
      max-width: 77.77%;
      margin: 0 auto;
      position: relative;
      @include m.mq('middle') {
        max-width: 100%;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 2px;
        height: calc(100% - 80px);
        background: #A4B7CB;
        position: absolute;
        left: 119px;
        top: 40px;
        @include m.mq('middle') {
          left: 60px;
          top: 30px;
          height: calc(100% - 60px);
        }
      }
      &_item{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #CCCCCC;
        padding: 20px;
        @include m.mq('middle') {
          padding: 15px 0;
        }
        &_year{
          font-family: var.$font-en;
          font-size: 2.4rem;
          font-weight: bold;
          color: #A4B7CB;
          line-height: 1.4;
          width: 130px;
          @include m.mq('middle') {
            font-size: 1.6rem;
            line-height: 1.7;
            width: 70px;
          }
        }
        &_contents{
          flex: 1;
        }
        &_row{
          display: flex;
          margin-bottom: 20px;
          @include m.mq('middle') {
            margin-bottom: 10px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
        &_month{
          position: relative;
          color: #3AA1D5;
          font-size: 1.6rem;
          width: 10px;
          text-align: right;
          line-height: 1.9;
          @include m.mq('middle') {
            font-size: 1.4rem;
            width: 10px;
          }
          span{
            font-family: var.$font-en;
            font-weight: bold;
          }
          &:before{
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #3AA1D5;
            border-radius: 50%;
            position: absolute;
            left: -35px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            @include m.mq('middle') {
              left: -14px;
              top: 9px;
              bottom: inherit;
            }
          }
        }
        &_txt{
          margin: 0;
          padding-left: 0;
          flex: 1;
          font-weight: bold;
          @include m.mq('middle') {
            padding-left: 5px;
            position: relative;
            top: 3px;
            font-size: 1.2rem;
          }
        }
      }
    }
    &_note{
      font-size: 1.2rem;
      max-width: 77.77%;
      margin: 15px auto 0;
    }
  }

}
