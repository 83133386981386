@use "../setting/setting" as m;
@use "variable" as var;

.home .header{
  position: fixed;
  left: 0;
  top: 0;
}
.header{
  width: 100%;
  z-index: 1111;
  background: rgba(255,255,255,0.95);
  @include m.mq('middle') {
    background: rgba(255,255,255,0.9);
  }
  &_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    @include m.mq('middle') {
      padding: 15px 15px;
    }
  }
  &_logo{
    margin: 0;
    @include m.mq('middle') {
      width: 220px;
      line-height: 1;
    }
    a{
      display: block;
    }
  }
  &_right{
    display: flex;
    align-items: center;
    @include m.mq('middle') {
      display: none;
    }
  }
  &_navi{
    font-weight: 600;
    &_list{
      display: flex;
      &_item{
        position: relative;
        &:last-child{
          margin-right: 20px;
        }
        a{
          display: block;
          padding: 20px;
          transition: all 0.3s ease-in-out;
          &:hover{
            color: #C0D9F2;
            opacity: 1;
          }
          &:after{
            content: "";
            display: block;
            width: 1px;
            height: 0;
            background: #C0D9F2;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            margin: auto;
            transition: all 0.3s ease-in-out;
          }
        }
        &.active{
          a{
            color: #C0D9F2;
            &:after{
              height: 25px;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &.active-page{
          a{
            color: #C0D9F2;
            &:after{
              height: 25px;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
  &_contact{
    text-align: center;
    background: #C0D9F2;
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    a{
      display: block;
      padding: 20px 7px;
      img{
        width: 29px;
        margin: 0 auto 8px;
        display: block;
      }
      &:hover{
        opacity: 1;
      }
    }
    &:hover{
      background: #3AA1D6;
      transition: all 0.3s ease-in-out;
    }
  }
}


.drawer-hamburger{
  width: 3.7rem;
  padding: 23px 15px 40px;
  @include m.mq('large') {
    display: none;
  }
  .drawer-hamburger-icon{
    color: #3AA1D5;
    background: #3AA1D5;
    height: 0;
    &:before,&:after{
      background: #3AA1D5;
      height: 3px;
    }
    &:before{
      top: -6px;
    }
    &:after{
      top: 6px;
    }
  }
}
.drawer-nav{
  width: 100%;
  background: #3AA1D5;
}
.drawer--right .drawer-nav{
  right: -100%;
}
.drawer--right.drawer-open .drawer-hamburger{
  right: 0;
}
.drawer-open{
  .drawer-hamburger-icon{
    &:before{
      top: 0;
      background: #fff;
    }
    &:after{
      top: 0;
      background: #fff;
    }
  }
}

.drawer-menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  .drawer-menu-item{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 10px;
  }
}


.fixed-instagram{
  z-index: 112;
  position: fixed;
  display: inline-block;
  right: 0;
  top: 50%;
  background: #EBE4E4;
  display: inline-block;
  border: 3px solid #FF0000;
  border-radius: 10px 0 0 10px;
  @include m.mq('middle') {
    border: 2px solid #FF0000;
    top: 70%;
  }
  a{
    display: block;
    padding: 30px 10px 20px;
    @include m.mq('middle') {
      padding: 20px 5px 10px;
      img{
        width: 20px;
      }
    }
  }
}

.page-template-page-zero-03-fps{
  .fixed-instagram{
    display: none !important;
  }
}
