@use "./setting/setting" as m;
@use "variable" as var;

.company{

  &_sec{

  }

  &_mission{
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 75px;
    }
    &_txt{
      font-weight: bold;
      margin-bottom: 40px;
      @include m.mq('middle') {
        margin-bottom: 30px;
      }
    }
    &_images{
      display: flex;
      &_item{
        margin-right: 10px;
        @include m.mq('middle') {
          margin-right: 5px;
        }
        &:last-child{
          margin-right: 0;
        }
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &_message{
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 60px;
    }
    &_txt{
      font-weight: bold;
      margin-bottom: 40px;
      @include m.mq('middle') {
        margin-bottom: 30px;
      }
    }
    &_flex{
      display: flex;
      @include m.mq('middle') {
        display: block;
      }
      &_left{
        flex:1;
        margin-right: 11.11%;
        @include m.mq('middle') {
          margin: 0 0 20px 0;
        }
      }
      &_right{
        width: 35.55%;
        @include m.mq('middle') {
          width: 100%;
        }
      }
    }
    &_images{
      //display: flex;
      @include m.mq('middle') {
        display: flex;
      }
      &_item{
        margin-bottom: 20px;
        @include m.mq('middle') {
          margin-right: 5px;
        }
        &:last-child{
          margin-bottom: 0;
          @include m.mq('middle') {
            margin-right: 0;
          }
        }
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &_profile{
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 60px;
    }
    &_box{
      background: #EBF5FF;
      padding: 6.66% 11.11%;
      border-radius: 30px;
      @include m.mq('middle') {
        padding: 30px 20px 20px;
        border-radius: 20px;
      }
    }
    &_table{
      margin-bottom: 30px;
      @include m.mq('middle') {
        font-size: 1.2rem;
        margin-bottom: 20px;
      }
      tr{
        &:first-child{
          th,td{
            border-top: 1px solid #CCCCCC;
          }
        }
        th{
          width: 27.77%;
          text-align: left;
          border-bottom: 1px solid #CCCCCC;
          padding: 20px 0;
          font-weight: 600;
          @include m.mq('middle') {
            width: 70px;
            padding: 15px 0;
          }
        }
        td{
          border-bottom: 1px solid #CCCCCC;
          padding: 20px 0;
          @include m.mq('middle') {
            padding: 15px 0;
          }
        }
      }
    }
    &_map{
      iframe{
        width: 100%;
        height: 400px;
        @include m.mq('middle') {
          height: 250px;
        }
      }
    }
  }

  &_history{
    &_time-table{
      max-width: 77.77%;
      margin: 0 auto;
      position: relative;
      @include m.mq('middle') {
        max-width: 100%;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 2px;
        height: calc(100% - 80px);
        background: #A4B7CB;
        position: absolute;
        left: 119px;
        top: 40px;
        @include m.mq('middle') {
          left: 60px;
          top: 30px;
          height: calc(100% - 60px);
        }
      }
      &_item{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #CCCCCC;
        padding: 20px;
        font-weight: bold;
        &:first-child{
          border-top: 1px solid #CCCCCC;
        }
        @include m.mq('middle') {
          padding: 15px 0;
        }
        &_year{
          font-family: var.$font-en;
          font-size: 2.4rem;
          font-weight: bold;
          color: #A4B7CB;
          line-height: 1.4;
          width: 130px;
          @include m.mq('middle') {
            font-size: 1.6rem;
            line-height: 1.7;
            width: 70px;
          }
        }
        &_contents{
          flex: 1;
        }
        &_row{
          display: flex;
          margin-bottom: 20px;
          @include m.mq('middle') {
            margin-bottom: 10px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
        &_month{
          position: relative;
          color: #3AA1D5;
          font-size: 1.6rem;
          width: 40px;
          text-align: right;
          line-height: 1.9;
          @include m.mq('middle') {
            font-size: 1.4rem;
            width: 35px;
          }
          span{
            font-family: var.$font-en;
            font-weight: bold;
          }
          &:before{
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #3AA1D5;
            border-radius: 50%;
            position: absolute;
            left: -35px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            @include m.mq('middle') {
              left: -14px;
              top: 9px;
              bottom: inherit;
            }
          }
        }
        &_txt{
          margin: 0;
          padding-left: 30px;
          flex: 1;
          @include m.mq('middle') {
            padding-left: 15px;
            position: relative;
            top: 3px;
            font-size: 1.2rem;
          }
        }
      }
    }
    &_undertxt{
      font-size: 1.4rem;
      font-weight: 500 !important;
      margin-top: 50px;
      @include m.mq('middle'){
        font-size: 1.2rem;
        margin-top: 25px;
      }
    }
  }

}
