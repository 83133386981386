@use "./setting/setting" as m;
@use "variable" as var;

.zero-03-fps{

  &_fixed-btn{
    position: fixed;
    right: 0;
    bottom: 10%;
    z-index: 11;
    padding: 30px 0 30px 5px;
    width: 90px;
    border-radius: 10px 0 0 10px;
    background: #A4B7CB;
    color: #fff;
    writing-mode: vertical-rl;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.4em;
    text-align: center;
    font-weight: bold;
    @include m.mq('middle') {
      display: none;
    }
    a{
      display: block;
      &:hover{
        color: #fff;
      }
    }
  }

  &_head{
    position: relative;
    padding: 50px 0;
    margin-bottom: 180px;
    @include m.mq('middle') {
      padding: 0;
      margin-bottom: 45px;
    }
    &:before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 57%;
      height: 100%;
      background: #EBF5FF;
      @include m.mq('middle') {
        display: none;
      }
    }
    &_flex{
      display: flex;
      align-items: center;
      @include m.mq('middle') {
        display: block;
      }
      &_left{
        width: 28%;
        @include m.mq('middle') {
          width: auto;
          position: relative;
          z-index: 1;
          margin-bottom: 30px;
        }
      }
      &_right{
        flex: 1;
      }
    }
    &_heading{
      font-size: 4rem;
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 25px;
      @include m.mq('middle') {
        font-size: 2rem;
        margin-bottom: 20px;
        br{
          display: none;
        }
      }
    }
    &_list{
      font-size: 2rem;
      font-weight: bold;
      @include m.mq('middle') {
        font-size: 1.6rem;
      }
      &_item{
        margin-bottom: 10px;
        @include m.mq('middle') {
          margin-bottom: 5px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 18px;
          height: 18px;
          background: url(./assets/img/icon_check_blue.webp) no-repeat center center;
          background-size: cover;
          margin-right: 10px;
          vertical-align: middle;
          position: relative;
          bottom: 2px;
        }
      }
    }
    &_thumb{
      position: relative;
      z-index: 1;
      margin: 0;
    }
  }

  &_desc{
    margin-bottom: 70px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    &_box{
      border: 5px solid #3AA1D5;
      padding: 0 5.55% 3.33%;
      border-radius: 3.33%;
      @include m.mq('middle') {
        border: 3px solid #3AA1D5;
        border-radius: 10px;
        padding: 0 20px 20px;
      }
    }
    &_heading{
      text-align: center;
      margin-bottom: 30px;
      @include m.mq('middle') {
        margin-bottom: 10px;
      }
      span{
        display: inline-block;
        background: #fff;
        color: #3AA1D5;
        font-family: var.$font-en;
        font-weight: 900;
        font-size: 4.5rem;
        position: relative;
        margin-top: -33px;
        margin: -33px 0 0;
        letter-spacing: 0.1em;
        position: relative;
        bottom: 2px;
        @include m.mq('middle') {
          font-size: 2.8rem;
          margin: -50px 0 0;
          position: relative;
          bottom: 8px;
        }
      }
    }
    &_thumb{
      text-align: center;
      margin-bottom: 45px;
      @include m.mq('middle') {
        margin-bottom: 20px;
      }
    }
    &_txt{
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  &_bnr-youtube{
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
  }

  &_point{
    margin-bottom: 140px;
    @include m.mq('middle') {
      margin-bottom: 45px;
    }
    &_heading{
      text-align: center;
      font-weight: 600;
      font-size: 4.5rem;
      line-height: 1.2;
      margin-bottom: 30px;
      @include m.mq('middle') {
        font-weight: 500;
        font-size: 2.2rem;
        margin-bottom: 20px;
      }
      &_blue{
        color: #3AA1D5;
      }
      &_num{
        font-family: var.$font-en;
        font-weight: 600;
        color: #3AA1D5;
        font-size: 8rem;
        @include m.mq('middle') {
          font-size: 4.8rem;
        }
      }
    }
    &_sec{
      background: #EBF5FF;
      border-radius: 30px;
      padding: 40px 50px 50px;
      margin-bottom: 30px;
      @include m.mq('middle') {
        border-radius: 20px;
        padding: 30px 20px 30px;
      }
      &--last{
        margin-bottom: 0;
      }
      &_head{
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        @include m.mq('middle') {
          display: block;
          margin-bottom: 25px;
        }
        &_figure{
          margin: 0;
          @include m.mq('middle') {
            text-align: center;
            margin-bottom: 15px;
            img{
              width: 90px;
            }
          }
        }
        &_heading{
          margin: 0 0 0 30px;
          font-size: 3rem;
          line-height: 1.6;
          font-weight: 600;
          @include m.mq('middle') {
            margin: 0;
            font-size: 2rem;
            br{
              display: none;
            }
          }
        }
      }
      &_case{
        margin-bottom: 45px;
        @include m.mq('middle') {
          margin-bottom: 40px;
        }
        &_heading{
          font-size: 2rem;
          margin-bottom: 15px;
          font-weight: +00;
          @include m.mq('middle') {
            font-size: 1.6rem;
            text-align: center;
          }
          span{
            display: inline-block;
            width: 350px;
            background: #fff;
            text-align: center;
            padding: 15px 0;
            border-radius: 30px;
            @include m.mq('middle') {
              padding: 10px 0;
              width: 240px;
            }
          }
        }
        &_txt{
          position: relative;
          z-index: 1;
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 0;
          @include m.mq('middle') {
            font-size: 1.4rem;
            margin-bottom: 15px;
          }
          &:before{
            content: "";
            display: inline-block;
            width: 20px;
            height: 19px;
            background: url(./assets/img/icon_arrow_right_black.webp) no-repeat center center;
            background-size: cover;
            vertical-align: middle;
            position: relative;
            bottom: 2px;
            margin-right: 10px;
            @include m.mq('middle') {
              display: block;
              margin: 0 auto 5px;
              transform:rotate(90deg);
            }
          }
          span{
            position: relative;
            display: inline-block;
            &:before{
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: 10px;
              width: 100%;
              height: 10px;
              background: #FCB5B5;
              z-index: -1;
              @include m.mq('middle') {
                height: 5px;
                bottom: -10px;
              }
            }
          }
        }
      }
      &_data{
        display: flex;
        margin-bottom: 70px;
        @include m.mq('middle') {
          display: block;
          margin-bottom: 20px;
        }
        &_item{
          width: 50%;
          @include m.mq('middle') {
            width: 100%;
          }
          &_head{
            margin-bottom: 10px;
            font-weight: 600;
            @include m.mq('middle') {
              font-size: 1.3rem;
            }
            &:before{
              content: "";
              display: inline-block;
              width: 40px;
              height: 40px;
              vertical-align: middle;
              position: relative;
              bottom: 2px;
              margin-right: 10px;
              @include m.mq('middle') {
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
            }
            &--blue{
              &:before{
                background: #68BBE5;
              }
            }
            &--green{
              &:before{
                background: #A9E5CE;
              }
            }
          }
          &_txt{
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 0;
            @include m.mq('middle') {
              font-size: 1.4rem;
            }
          }
        }
      }
      &_time{
        margin-bottom: 70px;
        text-align: center;
        @include m.mq('middle') {
          margin-bottom: 20px;
        }
      }
      &_table{
        line-height: 1.7;
        margin-bottom: 15px;
        @include m.mq('middle') {
        }
        tr{
          th{
            background: #3AA1D5;
            text-align: center;
            color: #fff;
            font-weight: 500;
            font-size: 1.6rem;
            padding: 15px;
            border: 1px solid #C4C4C4;
            font-weight: bold;
            @include m.mq('middle') {
              font-size: 1.4rem;
            }
          }
          td{
            background: #fff;
            padding: 15px;
            text-align: center;
            border: 1px solid #C4C4C4;
            @include m.mq('middle') {
              font-size: 1.2rem;
            }
            &.red{
              color: #E50F0F;
              background: #FAE4E4;
              font-size: 1.6rem;
              font-weight: bold;
              @include m.mq('middle') {
                font-size: 1.4rem;
              }
            }
            .small{
              font-size: 1rem;
              vertical-align: top;
            }
          }
        }
      }
      &_note{
        font-size: 1.2rem;
        margin-bottom: 0;
        &.fs-12{
          line-height: calc(16/12);
        }
        &.mb-20{
          @include m.mq('middle'){
            margin-bottom: 20px;
          }
        }
      }
      &_product{
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
        @include m.mq('middle') {
          margin-bottom: 30px;
          display: block;
        }
        &_item{
          width: 45%;
          @include m.mq('middle') {
            width: 100%;
            margin-bottom: 20px;
            &:last-child{
              margin-bottom: 0;
            }
          }
          &_thumb{
            margin-bottom: 0;
          }
          &_heading{
            position: relative;
            font-size: 2rem;
            padding: 15px 0;
            margin-bottom: 25px;
            border-bottom: 1px solid #3AA1D5;
            @include m.mq('middle') {
              padding: 10px 0;
              margin-bottom: 15px;
            }
            &:before,&:after{
              position: absolute;
              top: 100%;
              left: 50%;
              content: "";
              height: 0;
              width: 0;
            }
            &:before{
              border: 8px solid;
              border-top: 12px solid #3AA1D5;
              border-color: transparent;
              border-top-color: #3AA1D5;
              margin-left: -8px;
            }
            &:after{
              border: 6.5px solid;
              border-top: 10px solid;
              border-color: transparent;
              border-top-color: #EBF5FF;
              margin-left: -6.5px;
            }
          }
          &_txt{
            margin-bottom: 0;
          }
        }
      }
      &_bnr{
        &_thumb{
          margin-bottom: 50px;
          @include m.mq('middle') {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &_scene{
    margin-bottom: 80px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    &_heading{
      text-align: center;
      font-size: 3rem;
      font-weight: bold;
      font-weight: 600;
      margin-bottom: 40px;
      @include m.mq('middle') {
        font-size: 2rem;
        margin-bottom: 20px;
      }
      span{
        color: #3AA1D5;
        font-size: 4rem;
        @include m.mq('middle') {
          font-size: 3rem;
        }
      }
    }
    &_col-04{
      display: flex;
      justify-content: space-between;
      @include m.mq('middle') {
        flex-wrap: wrap;
      }
      &_item{
        width: 23%;
        @include m.mq('middle') {
          width: 49%;
          margin-bottom: 5%;
        }
        &_thumb{
          text-align: center;
          margin-bottom: 20px;
          @include m.mq('middle') {
            margin-bottom: 15px;
          }
        }
        &_heading{
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
          @include m.mq('middle') {
            font-size: 1.8rem;
          }
        }
        &_txt{
          margin-bottom: 0;
        }
      }
    }
  }

  &_maintenance{
    max-width: 86.66%;
    margin: 0 auto;
    border: 1px solid #3AA1D5;
    margin-bottom: 190px;
    font-weight: 600;
    @include m.mq('middle') {
      max-width: 100%;
      margin-bottom: 45px;
    }
    &_inner{
      background: #EBF5FF;
      border: 10px solid #fff;
      padding: 25px 45px 30px;
      @include m.mq('middle') {
        padding: 20px;
        border: 5px solid #fff;
      }
    }
    &_heading{
      text-align: center;
      font-size: 2.4rem;
      margin-bottom: 5px;
      @include m.mq('middle') {
        font-size: 2rem;
        margin-bottom: 15px;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 80px;
        height: 80px;
        background: url(./assets/img/zero-03-fps_maintenance_icon.webp) no-repeat center center;
        background-size: cover;
        vertical-align: middle;
        position: relative;
        bottom: 2px;
        margin-right: 20px;
        @include m.mq('middle') {
          width: 60px;
          height: 60px;
          display: block;
          margin: 0 auto 5px;
        }
      }
    }
    &_txt{
      text-align: center;
      margin-bottom: 0;
    }
  }
  &_bnr{
    margin-bottom: 180px;
    @include m.mq('middle') {
      margin-bottom: 90px;
    }
  }

}
