@use "../setting/setting" as m;
@use "variable" as var;

.privacypolicy{

  &_head{
    font-weight: bold;
    margin-bottom: 65px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
  }

  &_sec{
    margin-bottom: 55px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &_heading{
      position: relative;
      font-weight: bold;
      color: #3AA1D5;
      font-size: 2rem;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #C4C4C4;
      @include m.mq('middle') {
        font-size: 1.8rem;
      }
      &:after{
        content: "";
        display: block;
        width: 200px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -1px;
        z-index: 1;
        background: #3AA1D5;
        @include m.mq('middle') {
          width: 80px;
        }
      }
    }
    &_txt{
      margin-bottom: 0;
      a{
        color: #3AA1D5;
        text-decoration: underline;
      }
    }
    &_address{
      font-style: normal;
      margin-top: 30px;
      font-weight: bold;
      font-size: 1.6rem;
      @include m.mq('middle') {
        margin-top: 20px;
        font-size: 1.5rem;
      }
    }
  }

}
